<template>
  <EdgeStack
    :id="esId"
    :busy="busy"
    :confirmQuestion="$t('components.edgeStack.confirmQuestion')"
  >
    <template v-slot:header>
      <div class="pb-3 panel-title">{{ getTitleText }}</div>

      <XStepper
        :id="esId"
        :steps="stepperSteps"
        :mode="stepperMode"
        v-model="stepperCurrentStep"
        ref="xstepper"
      />
    </template>

    <template v-slot:footer>
      <XStepperNavigation
        v-if="isEditing"
        :id="esId"
        :steps="stepperSteps"
        :submit-button-text="getSubmitButtonText"
        @submit="onSubmit"
        v-model="stepperCurrentStep"
      />

      <AppButton
        v-if="!isEditing"
        :text="getSubmitButtonText"
        @click="onSubmit({ step: stepperCurrentStep })"
      />
    </template>

    <template v-slot:default>
      <keep-alive>
        <template v-if="stepperCurrentStep === 1">
          <Step1
            :primary-key="primaryKeyProxy"
            :form-data="getStep1FormData"
            :is-editing="isEditing"
            @save="onSave($event)"
            @dirty="onDirty(true)"
            ref="step1"
          />
        </template>

        <template v-else-if="stepperCurrentStep === 2">
          <Step2
            :primary-key="primaryKeyProxy"
            :form-data="getStep2FormData"
            :is-editing="isEditing"
            @save="onSave($event)"
            @dirty="onDirty(true)"
            ref="step2"
          />
        </template>

        <template v-else-if="stepperCurrentStep === 3">
          <Step3
            :form-data="getStep3FormData"
            :is-editing="isEditing"
            @save="onSave($event)"
            @dirty="onDirty(true)"
            ref="step3"
          />
        </template>
      </keep-alive>
    </template>
  </EdgeStack>
</template>

<script>
import pick from 'lodash/pick'
// import { useEndpoints } from '@/composables'

import EdgeStack from '@/components/modals/EdgeStack'
import XStepper from '@/components/stepper/XStepper'
import XStepperNavigation from '@/components/stepper/XStepperNavigation'

import AppButton from '@/components/form/AppButton.vue'

import { Step1, Step2, Step3 } from './add-edit'

export default {
  name: 'OperationPrimerAddEdit',

  components: {
    EdgeStack,
    XStepper,
    XStepperNavigation,
    AppButton,
    Step1,
    Step2,
    Step3,
  },

  props: {
    esId: {
      type: String,
      default: 'operation-primer-add-edit',
    },
    stepperMode: {
      type: String,
      default: 'free',
    },
    stepperStep: {
      type: Number,
      default: 1,
    },
    primaryKey: {
      required: false,
    },
    rawData: {
      type: Object,
      required: false,
    },
    busy: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const stepperSteps = [
      {
        title: this.$t('components.vehicleOperationPrimer.addEdit.tabs.part'),
        free: true,
      },
      {
        title: this.$t(
          'components.vehicleOperationPrimer.addEdit.tabs.subpart'
        ),
        free: false,
      },
      {
        title: this.$t(
          'components.vehicleOperationPrimer.addEdit.tabs.repairOption'
        ),
        free: false,
      },
    ]

    return {
      isLoading: false,
      stepperSteps,
      stepperCurrentStep: null,
      primaryKeyProxy: null,
      step3FromData: [],
    }
  },

  computed: {
    isEditing() {
      return !!this.rawData
    },

    getTitleText() {
      return this.isEditing
        ? this.$t('components.vehicleOperationPrimer.addEdit.headline.update')
        : this.$t('components.vehicleOperationPrimer.addEdit.headline.add')
    },

    getSubmitButtonText() {
      if (this.stepperCurrentStep > this.stepperSteps.length - 1)
        return this.$t('components.stepNavigation.finish')

      return this.isEditing
        ? this.$t('components.stepNavigation.update')
        : this.$t('components.stepNavigation.saveAndContinue')
    },

    getStep1FormData() {
      if (this.rawData) {
        const modelKeys = Object.keys(this.getFormModel({ step: 1 }))
        const picked = pick(this.rawData, modelKeys)
        return picked
      }

      return null
    },

    getStep2FormData() {
      if (this.rawData) {
        const modelKeys = Object.keys(this.getFormModel({ step: 2 }))
        const picked = pick(this.rawData, modelKeys)

        return [...picked.sub_parts]
      }

      return []
    },

    getStep3FormData() {
      return this.step3FromData
    },
  },

  async created() {},

  mounted() {
    this.$edgeStack.emitter.on(
      this.$edgeStack.getEventName('closed', this.esId),
      () => {
        this.stepperCurrentStep = 1
      }
    )
  },

  beforeDestroy() {
    this.$edgeStack.emitter.off(
      this.$edgeStack.getEventName('closed', this.esId)
    )
  },

  watch: {
    primaryKey: {
      immediate: true,
      handler(updatedId) {
        // getUserId -> updatedId could be null (in add mode)
        if (updatedId) {
          this.primaryKeyProxy = updatedId
        }
      },
    },

    stepperStep: {
      immediate: true,
      handler(updatedStep) {
        this.stepperCurrentStep = updatedStep
      },
    },
    rawData: {
      immediate: true,
      handler(data) {
        // getUserId -> updatedId could be null (in add mode)
        if (data) {
          this.step3FromData = data.sub_parts
        }
      },
    },
  },

  methods: {
    onDirty(type, id = this.esId) {
      return type === true
        ? this.$edgeStack.shouldConfirm(id)
        : this.$edgeStack.shouldNotConfirm(id)
    },

    onSave(e = { step: null, data: {} }) {
      if (e.step === 1) {
        this.primaryKeyProxy = e.data.id

        const steps = [
          {
            title: this.$t(
              'components.vehicleOperationPrimer.addEdit.tabs.part'
            ),
            free: true,
          },
          {
            title: this.$t(
              'components.vehicleOperationPrimer.addEdit.tabs.subpart'
            ),
            free: true,
          },
          {
            title: this.$t(
              'components.vehicleOperationPrimer.addEdit.tabs.repairOption'
            ),
            free: false,
          },
        ]

        this.$xStepper.defineSteps(this.esId, steps)
        this.beforeNextStep()
      }

      if (e.step === 2) {
        const steps = [
          {
            title: this.$t(
              'components.vehicleOperationPrimer.addEdit.tabs.part'
            ),
            free: true,
          },
          {
            title: this.$t(
              'components.vehicleOperationPrimer.addEdit.tabs.subpart'
            ),
            free: true,
          },
          {
            title: this.$t(
              'components.vehicleOperationPrimer.addEdit.tabs.repairOption'
            ),
            free: true,
          },
        ]

        this.step3FromData = e.data

        this.$xStepper.defineSteps(this.esId, steps)
        if (this.step3FromData.length > 0) {
          this.beforeNextStep()
        } else {
          this.onLastStep()
        }
      }

      if (e.step === 3) {
        this.$xStepper.defineSteps(this.esId, this.stepperSteps)
        this.onLastStep()
      }
    },

    beforeNextStep() {
      // define what happens about confirmation before exiting on next step (2)
      // on edit mode->after updating->should not confirm unless event triggered by user
      // on add mode->after saving->should confirm
      if (this.stepperMode === 'free') {
        this.$edgeStack.shouldNotConfirm(this.esId)
      } else {
        this.$edgeStack.shouldConfirm(this.esId)
      }

      this.$xStepper.navigate(this.esId).next()
    },

    onLastStep() {
      // notify that the table view needs to be updated

      // Should not confirm as we'r about to close it
      this.$edgeStack.shouldNotConfirm(this.esId)
      this.$edgeStack.close(this.esId)

      this.$edgeStack.emitter.on(
        this.$edgeStack.getEventName('closed', this.esId),
        () => {
          this.stepperCurrentStep = 1
        }
      )
      this.$emit('refresh')
    },

    onSubmit(step) {
      this.$refs[`step${step.step}`].submit()
    },

    getFormModel({ step }) {
      if (step === 1) {
        return {
          name: '',
          photo: '',
        }
      } else if (step === 2) {
        return {
          sub_parts: [],
        }
      } else if (step === 3) {
        return {
          sub_parts: [],
        }
      } else {
        console.error(`Unknown step param! - ${step}`)
      }
    },
  },
}
</script>

<style scoped>
.panel-title {
  font-size: 22px;
  font-weight: 500;
  color: #2e2e39;
  margin-top: -30px;
  margin-bottom: 6px;
}
</style>
