<template>
  <base-layout>
    <VehicleIndex
      :fst-id="`cocos`"
      :page-title="$t('components.vehicleManagement.headline.superCocos')"
      :endpoint="getEndpoint"
      :qso="getEndpointOptions"
      :tableHeaders="tableHeaders"
      :vehicleType="vehicleTypesMap.COCO.value"
    />
  </base-layout>
</template>

<script>
import { useEndpoints } from '@/composables'
import { VehicleIndex } from '@/composites/vehicle'
import { vehicleTypes } from '@/utils'
export default {
  name: 'SuperCocoIndex',
  components: {
    BaseLayout: () => import('@/views/shared/BaseLayout.vue'),
    VehicleIndex,
  },

  data() {
    return {
      vehicleTypesMap: vehicleTypes,
      tableHeaders: [
        {
          text: this.$t('components.vehicleManagement.table.columns.name'),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t('components.vehicleManagement.table.columns.battery'),
          width: '10%',
          sort: 'lock__power_level',
        },
        {
          text: this.$t('components.vehicleManagement.table.columns.imei'),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t('components.vehicleManagement.table.columns.qrCode'),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.vehicleManagement.table.columns.lastConnected'
          ),
          width: '13%',
          sort: null,
        },
        {
          text: this.$t(
            'components.vehicleManagement.table.columns.lastLocated'
          ),
          width: '13%',
          sort: null,
        },
        {
          text: this.$t('components.vehicleManagement.table.columns.flags'),
          width: '12%',
          sort: null,
        },
        {
          text: this.$t('components.vehicleManagement.table.columns.heartBeat'),
          width: '8%',
          sort: null,
        },
        {
          text: this.$t(
            'components.vehicleManagement.table.columns.lastLocation'
          ),
          width: '9%',
          sort: null,
        },
        {
          text: this.$t('components.vehicleManagement.table.columns.actions'),
          width: '5%',
          sort: null,
        },
      ],
    }
  },

  computed: {
    getEndpoint() {
      return useEndpoints.vehicle.index() + '?bike_category=C&'
    },

    getEndpointOptions() {
      return {
        prepend: '',
        append: '',
      }
    },
  },
}
</script>
