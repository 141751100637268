<template>
  <base-layout>
    <OperationPrimerAddEdit
      :es-id="ae.esId"
      :stepper-mode="ae.stepperMode"
      :stepper-step="ae.stepperStep"
      :primary-key="ae.primaryKey"
      :raw-data="ae.rawData"
      :busy="ae.busy"
      @refresh="$store.dispatch('fsTable/fetchData')"
    />
    <content-section :spacing="false">
      <div class="content-spacing">
        <title-plus
          :title="$t('components.vehicleOperationPrimer.table.headline')"
          @plus="onOpenES({ primaryKey: null })"
          class="mb-10"
        />
      </div>

      <FSTable
        :fst-id="`operation-primer`"
        :endpoint="indexDataEndpoint"
        :headers="tableHeaders"
      >
        <template v-slot:default="{ data }">
          <template v-for="(item, itemIndex) in data">
            <FSTableRow :key="itemIndex">
              <FSTableRowItem
                :text="item.id"
                :text-fallback="`--`"
                :truncate="-5"
              />
              <FSTableRowItem :text="item.name" :text-fallback="`--`" />
              <FSTableRowItem>
                <div class="flex items-center">
                  <OtoEditIcon @click="onOpenES({ primaryKey: item.id })" />
                  <MoreActions
                    :data="item"
                    @refresh="$store.dispatch('fsTable/fetchData')"
                  />
                </div>
              </FSTableRowItem>
            </FSTableRow>
          </template>
        </template>
      </FSTable>
    </content-section>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import ContentSection from '@/components/layout/ContentSection'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import MoreActions from '@/views/operation-primer/MoreActions.vue'
import TitlePlus from '@/components/ui/TitlePlus'
import OperationPrimerAddEdit from '@/views/operation-primer/OperationPrimerAddEdit.vue'

import { getFormattedDateTime } from '@/utils/datetime'

import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'
import { useEndpoints } from '@/composables'

export default {
  name: 'OperationPrimerIndex',

  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    OtoEditIcon,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    OperationPrimerAddEdit,
    MoreActions,
  },

  data() {
    return {
      indexDataEndpoint: useEndpoints.operationPrimer.vehicleInspectionPart.index(),

      tableHeaders: [
        {
          text: this.$t('components.vehicleOperationPrimer.table.columns.id'),
          width: '30%',
          sort: null,
        },
        {
          text: this.$t('components.vehicleOperationPrimer.table.columns.name'),
          width: '60%',
          sort: null,
        },
        {
          text: this.$t(
            'components.vehicleOperationPrimer.table.columns.action'
          ),
          width: '10%',
          sort: null,
        },
      ],
      // add-edit
      ae: {
        // EdgeStack component
        esId: 'operation-primer-add-edit',

        // XStepper component
        stepperMode: 'strict',
        stepperStep: 1,

        // Data
        busy: true,
        rawData: null,
        primaryKey: null,
      },
    }
  },
  mounted() {},

  computed: {},

  methods: {
    getFormattedDateTime,
    async onOpenES({ primaryKey }) {
      if (typeof primaryKey === 'string') {
        this.ae.busy = true
        this.$edgeStack.open(this.ae.esId)
        this.$http
          .get(
            useEndpoints.operationPrimer.vehicleInspectionPart.details(
              primaryKey
            )
          )
          .then((res) => {
            console.log('onOpenES', res)
            this.ae.rawData = res.data
            this.ae.primaryKey = primaryKey
            this.ae.stepperMode = 'free'

            this.ae.busy = false
          })
          .catch((err) => {
            console.warn({ err })

            this.$edgeStack.close(this.ae.esId)
            this.$notify({
              group: 'bottomRight',
              type: 'error',
              title: `Server Error`,
              text: 'Failed to retrieve data. Please try again.',
            })
          })

        return
      }

      this.ae.stepperMode = 'strict'

      this.ae.busy = false
      this.ae.rawData = null
      this.ae.primaryKey = null

      this.$edgeStack.open(this.ae.esId)
    },
  },
}
</script>
