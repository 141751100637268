<template lang="">
  <base-layout>
    <VehicleModelIndex
      fstId="org-vehicle-model-index"
      :endpoint="getUrl"
      :roleType="getRoleType"
      :isHidePlus="getHidePlusStatus"
    />
  </base-layout>
</template>
<script>
import BaseLayout from '@/views/shared/BaseLayout'
import VehicleModelIndex from '@/views/vehicle-manufacturers/VehicleModelIndex.vue'
import { VehicleModelConfig } from '@/config/VehicleModelConfig'
export default {
  name: 'VehicleManufacturersDetails',
  components: {
    BaseLayout,
    VehicleModelIndex,
  },
  computed: {
    getUrl() {
      if (this.$user.is_superadmin) {
        return VehicleModelConfig.api.indexData()
      } else {
        return VehicleModelConfig.api.org.index(this.$org.id)
      }
    },
    getRoleType() {
      if (this.$user.is_superadmin) {
        return 'admin'
      } else {
        return 'owner'
      }
    },
    getHidePlusStatus() {
      if (this.$user.is_superadmin) {
        return false
      } else {
        return true
      }
    },
  },
}
</script>
<style lang=""></style>
