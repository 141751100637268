<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <loading :active.sync="isLoading" :is-full-page="false" />

    <form @submit.prevent="handleSubmit(submitStep)" class="px-6">
      <AppInput
        v-model="form.name"
        type="text"
        :name="
          $t('components.vehicleOperationPrimer.addEdit.steps.part.title.name')
        "
        :label="
          $t('components.vehicleOperationPrimer.addEdit.steps.part.title.name')
        "
        rules="required"
        placeholder="e.g. Motor"
      />
      <UploadImageAlt
        class="max-h-14"
        :current="form.photo"
        :text="
          $t(
            'components.vehicleOperationPrimer.addEdit.steps.part.placeholder.photo'
          )
        "
        :button-text="
          $t('components.vehicleOperationPrimer.addEdit.steps.part.title.photo')
        "
        :disabled="false"
        v-model="form.photo"
      />

      <button type="submit" ref="submitButton" class="hidden">
        Save
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import { useEndpoints } from '@/composables'
import { deepCompareObjects } from '@/utils'

export default {
  name: 'Step1',

  components: {
    UploadImageAlt: () => import('@/components/form/UploadImageAlt'),
  },

  props: {
    primaryKey: {
      type: null,
      required: false,
    },
    formData: {
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,

      form: {
        name: '',
      },
    }
  },

  computed: {},

  watch: {
    // sync props.formData with $data.from
    formData: {
      immediate: true,
      deep: true,
      handler(data) {
        if (data) {
          this.form = { ...data }
        }
      },
    },

    form: {
      immediate: false,
      deep: true,
      handler(updatedFormData) {
        if (deepCompareObjects(this.formData, updatedFormData)) {
          return
        }
        this.$emit('dirty', { step: 1, data: this.form })
      },
    },
  },

  methods: {
    async submit() {
      this.$refs.submitButton.click()
    },

    async submitStep() {
      const method = this.isEditing ? 'PATCH' : 'POST'
      const url = this.isEditing
        ? useEndpoints.operationPrimer.vehicleInspectionPart.update(
            this.primaryKey
          )
        : useEndpoints.operationPrimer.vehicleInspectionPart.create()

      const formDataProxy = { ...this.form }
      if (typeof formDataProxy.photo === 'string') {
        delete formDataProxy.photo
      }

      const data = new FormData()
      for (const key in formDataProxy) {
        data.append(key, formDataProxy[key])
      }
      this.isLoading = true
      await this.$http({ method, url, data })
        .then((res) => {
          this.$emit('save', { step: 1, data: res.data })

          const message = `Part ${
            this.isEditing ? 'updated' : 'added'
          } successfully`

          this.$notify({
            group: 'bottomLeft',
            type: 'success',
            title: 'Success',
            text: message,
          })
        })
        .catch((err) => {
          // todo: emit fail?
          console.error('Error occured!', err)
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: 'Error occured!',
            text: err.response.data.detail,
          })
        })
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>

<style lang="scss" scoped></style>
