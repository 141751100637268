<template>
  <base-layout>
    <content-section :spacing="false">
      <div class="content-spacing">
        <title-plus
          :title="$t('components.chargingDock.table.headline')"
          :hidePlus="true"
          class="mb-10"
        />
      </div>

      <FSTable
        :fst-id="`charging-dock`"
        :endpoint="indexDataEndpoint"
        :headers="tableHeaders"
        :isSyncEnabled="true"
      >
        <template v-slot:default="{ data }">
          <template v-for="(item, itemIndex) in data">
            <FSTableRow :key="itemIndex">
              <FSTableRowItem>
                <span
                  v-tooltip="item.kuhmute_hub_uuid"
                  class="text-blue-600 cursor-pointer"
                  @click="onShowChargingDockPorts(item)"
                >
                  {{ $truncate(item.kuhmute_hub_uuid, -12) }}
                </span>
              </FSTableRowItem>
              <FSTableRowItem :text="item.name" :text-fallback="`--`" />
              <FSTableRowItem :text="item.address" :text-fallback="`--`" />
              <FSTableRowItem :text="item.total_ports" :text-fallback="`--`" />
              <FSTableRowItem
                :text="item.available_ports"
                :text-fallback="`--`"
              />
              <FSTableRowItem
                :text="item.unavailable_ports"
                :text-fallback="`--`"
              />
              <FSTableRowItem>
                <x-status
                  :variant="item.status === 'Available' ? 'green' : 'gray'"
                  :text="item.status"
                  profile="vehicle"
                />
              </FSTableRowItem>
              <FSTableRowItem>
                <div class="flex items-center">
                  <!-- <OtoEditIcon @click="onOpenES({ primaryKey: item.id })" /> -->
                  <MoreActions
                    :data="item"
                    @refresh="$store.dispatch('fsTable/fetchData')"
                  />
                </div>
              </FSTableRowItem>
            </FSTableRow>
          </template>
        </template>
      </FSTable>
      <ChargingDockPortIndex />
    </content-section>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import ContentSection from '@/components/layout/ContentSection'
// import OtoEditIcon from '@/components/ui/OtoEditIcon'
import MoreActions from '@/views/charging-dock/MoreActions.vue'
import TitlePlus from '@/components/ui/TitlePlus'
import XStatus from '@/components/badge/XStatus'
import { getFormattedDateTime } from '@/utils/datetime'
import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'
import { useEndpoints } from '@/composables'
import { ChargingDockPortIndexConfig } from '@/config/ChargingDockPortIndexConfig'

import ChargingDockPortIndex from '@/views/charging-dock/ChargingDockPortIndex.vue'

import { EventBus } from '@/utils'
export default {
  name: 'ChargingDockIndex',

  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    // OtoEditIcon,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    // OperationPrimerAddEdit,
    MoreActions,
    XStatus,
    ChargingDockPortIndex,
  },

  data() {
    return {
      indexDataEndpoint: useEndpoints.vehicle.chargingDock.index(),

      tableHeaders: [
        {
          text: 'Kuhmute UUID',
          width: '10%',
          sort: null,
        },
        {
          text: 'Name',
          width: '10%',
          sort: null,
        },
        {
          text: 'Address',
          width: '10%',
          sort: null,
        },
        {
          text: 'Total Ports',
          width: '10%',
          sort: null,
        },
        {
          text: 'Available Ports',
          width: '10%',
          sort: null,
        },
        {
          text: 'Unavailable ports',
          width: '10%',
          sort: null,
        },
        {
          text: 'Status',
          width: '10%',
          sort: null,
        },
        {
          text: 'Action',
          width: '10%',
          sort: null,
        },
      ],
      // add-edit
      ae: {
        // EdgeStack component
        esId: 'operation-primer-add-edit',

        // XStepper component
        stepperMode: 'strict',
        stepperStep: 1,

        // Data
        busy: true,
        rawData: null,
        primaryKey: null,
      },
    }
  },
  mounted() {},

  computed: {},

  methods: {
    getFormattedDateTime,
    onShowChargingDockPorts(data) {
      EventBus.$emit(ChargingDockPortIndexConfig.events.editingData, data)
      dispatchEvent(new Event(ChargingDockPortIndexConfig.events.sorToggle))
    },
  },
}
</script>
