<template lang="">
  <div>
    <t-dropdown variant="smActions">
      <template
        #trigger="{
          mousedownHandler,
          focusHandler,
          blurHandler,
          keydownHandler,
        }"
      >
        <button
          class="focus:outline-none"
          aria-label="More Actions"
          aria-haspopup="true"
          @mousedown="mousedownHandler"
          @focus="focusHandler"
          @blur="blurHandler"
          @keydown="keydownHandler"
        >
          <svg
            class="mt-1 w-6 h-5 text-oGray hover:text-oDark"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
            ></path>
          </svg>
        </button>
      </template>

      <template #default="{ hide }">
        <div
          @click="hide()"
          class="py-1 mt-px bg-white rounded-md shadow-md z-200"
        >
          <t-dropdown-item @click="executeAction('delete')" variant="danger">
            {{ $t('components.chargingDock.itemActions.delete') }}
          </t-dropdown-item>
        </div>
      </template>
    </t-dropdown>
  </div>
</template>
<script>
import { useEndpoints } from '@/composables'
import { convertToTitleCase } from '@/components/picker/date-range/utils/CollectionUtils'
export default {
  name: 'MoreActionsDropdown',
  components: {
    TDropdownItem: () => import('@/components/dropdown/TDropdownItem'),
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data: () => ({}),
  methods: {
    async executeAction(type) {
      if (type === 'delete') {
        const endpoint = useEndpoints.vehicle.chargingDock.delete(this.data.id)

        await this.$http
          .delete(endpoint)
          .then(() => {
            this.$notify({
              group: 'bottomRight',
              type: `success`,
              title: `Success`,
              text: `Deleted successfully`,
            })
          })
          .catch((error) => {
            const e = error.response
            console.error('v-catch', e)

            this.$notify(
              {
                group: 'bottomLeft',
                type: 'error',
                title: e.data.code
                  ? `${convertToTitleCase(e.data.code)}`
                  : `Error Occurred [Code: ${e.status}]`,
                text: e.data.message ?? 'The action could not be executed',
              },
              5000
            )
          })
          .finally(() => {
            this.$emit('refresh')
          })
        return
      }
    },
  },
}
</script>
<style lang=""></style>
