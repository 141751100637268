<template>
  <slide-over-right
    :id="sorId"
    v-on:showModal="showModal"
    :fullWidth="true"
    editType="charging-dock-port"
  >
    <loading :active.sync="isLoading"></loading>

    <template v-slot:header>
      <div class="flex gap-2">
        <slide-over-right-trigger :id="sorId">
          <div
            class="items-center justify-center mr-4 cursor-pointer back-button"
          >
            <i class="fas fa-arrow-left"></i>
          </div>
        </slide-over-right-trigger>
        <div>
          <div class="font-semibold text-19px text-oCharcoal flex">
            ID:
            <span> {{ dockData.kuhmute_hub_uuid }} </span>
          </div>
          <div>
            <span
              >Name: {{ dockData.name || '--' }}, Serial Number:
              {{ dockData.id || '--' }}</span
            >
          </div>
        </div>
      </div>
    </template>
    <div
      :key="`chargingDockPortIndex_${counter}`"
      class=" mt-3 pt-5 border-oLightestGray border-t-2"
    >
      <STable
        :sId="'charging-dock-port-list'"
        :headers="chargingDockPortHeaders"
      >
        <template
          v-for="(chargingDockPortItem,
          chargingDockPortItemIndex) in chargingDockPortList"
        >
          <STableRow :key="chargingDockPortItemIndex" text-fallback-always>
            <STableRowItem :text="chargingDockPortItem.number" />
            <STableRowItem
              :text="
                getFormattedDateTime(
                  chargingDockPortItem.updated,
                  'D MMM YYYY, h:mm:ss a'
                )
              "
            />
            <STableRowItem>
              <router-link
                v-if="chargingDockPortItem.bike"
                :to="{
                  name: 'ViewVehicleProfile',
                  params: { id: chargingDockPortItem.bike.id },
                }"
                :class="`text-blue-600 mr-5`"
                target="_blank"
              >
                {{ chargingDockPortItem.bike.qr_code }}
              </router-link>
              <span v-else>--</span>
            </STableRowItem>

            <STableRowItem
              :text="
                chargingDockPortItem.bike
                  ? chargingDockPortItem.bike.lock.lock_id
                  : '--'
              "
            />
            <STableRowItem
              :text="
                chargingDockPortItem.bike
                  ? chargingDockPortItem.bike.charging_adapter
                    ? chargingDockPortItem.bike.charging_adapter.adapter_code
                    : '--'
                  : '--'
              "
            />
            <STableRowItem>
              <x-status
                :variant="getPortStatusVariant(chargingDockPortItem.status)"
                :text="getPortStatusText(chargingDockPortItem.status)"
                profile="vehicle"
              />
            </STableRowItem>
          </STableRow> </template
      ></STable>
    </div>
  </slide-over-right>
</template>

<script>
import { EventBus } from '@/utils/EventBus'
import { ChargingDockPortIndexConfig } from '@/config/ChargingDockPortIndexConfig'
import { useEndpoints } from '@/composables'
import { getFormattedDateTime } from '@/utils/datetime'
import { STable, STableRow, STableRowItem } from '@/components/s-table'
import XStatus from '@/components/badge/XStatus'
export default {
  name: 'ChargingDockPortIndex',
  components: {
    SlideOverRight: () => import('@/components/modals/SlideOverRight'),
    STable,
    STableRow,
    STableRowItem,
    XStatus,
    SlideOverRightTrigger: () =>
      import('@/components/modals/SlideOverRightTrigger'),
  },
  data() {
    return {
      isLoading: false,
      counter: 0,
      dockData: {},
      dockPortsData: [],
      sorId: ChargingDockPortIndexConfig.events.sorId,
      chargingDockPortHeaders: [
        {
          text: 'Port Number',
          width: '10%',
          sort: null,
        },
        {
          text: 'Last Updated',
          width: '10%',
          sort: null,
        },
        {
          text: 'Vehicle Qr',
          width: '10%',
          sort: null,
        },
        {
          text: 'Vehicle IMEI Number',
          width: '10%',
          sort: null,
        },
        {
          text: 'Adapter Code',
          width: '10%',
          sort: null,
        },
        {
          text: 'Docked Status',
          width: '10%',
          sort: null,
        },
      ],
      chargingDockPortList: [],
    }
  },
  computed: {},
  mounted() {
    EventBus.$on(ChargingDockPortIndexConfig.events.editingData, (item) => {
      this.dockData = item
      this.chargingDockPortList = []
      this.fetchChargingPortList(item.id)
    })
  },
  methods: {
    getFormattedDateTime,
    showModal() {
      dispatchEvent(new Event(ChargingDockPortIndexConfig.events.sorToggle))
    },
    async fetchChargingPortList(dockId) {
      this.isLoading = true
      await this.$http
        .get(useEndpoints.vehicle.chargingDock.portIndex(dockId))
        .then((res) => {
          this.chargingDockPortList = res.data.data
        })
        .catch((err) => {
          this.$notify({
            group: 'bottomRight',
            type: 'error',
            title: 'Failed',
            text: 'Failed to load port list',
          })
          console.log('port-list-err', err.response)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getPortStatusText(status) {
      if (status === 'unavailable') {
        return 'Unavailable'
      } else if (status === 'available') {
        return 'Available'
      }
      return status
    },
    getPortStatusVariant(status) {
      if (status === 'unavailable') {
        return 'gray'
      } else if (status === 'available') {
        return 'green'
      }
      return 'gray'
    },
  },
}
</script>

<style></style>
