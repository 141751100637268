import { render, staticRenderFns } from "./OperationPrimerAddEdit.vue?vue&type=template&id=a72a5476&scoped=true&"
import script from "./OperationPrimerAddEdit.vue?vue&type=script&lang=js&"
export * from "./OperationPrimerAddEdit.vue?vue&type=script&lang=js&"
import style0 from "./OperationPrimerAddEdit.vue?vue&type=style&index=0&id=a72a5476&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a72a5476",
  null
  
)

export default component.exports