export const ChargingDockPortIndexConfig = {
  api: {},
  events: {
    name: 'charging-dock-port-index-config',
    // refresh-index-data
    refresh: `rid-charging-dock-port-index-config`,
    // slideover-right
    sorId: 'charging-dock-port-index-config',
    sorOpen: 'sor-open-charging-dock-port-index-config',
    sorClose: 'sor-close-charging-dock-port-index-config',
    sorToggle: 'sor-toggle-charging-dock-port-index-config',
    // editing-data
    editingData: 'edit-charging-dock-port-index-config-data',
    // viewing-data
    viewingData: 'view-charging-dock-port-index-config-data',
  },
}
