<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <loading :active.sync="isLoading" :is-full-page="false" />

    <form @submit.prevent="handleSubmit(submitStep)" class="px-6">
      <div v-if="form.length > 0">
        <div
          v-for="(parent, parentIndex) in form"
          :key="`parent-${parentIndex}`"
          class="pb-2"
        >
          <div class="flex items-center my-2 text-sm font-bold text-gray-500">
            <span>{{ parent.name }}</span>
            <div
              class="col-span-1 ml-2 cursor-pointer focus:text-gray-400"
              @click="onAddSubpartRepairOption(parentIndex)"
            >
              <i class="fas fa-plus-circle" style="color: black"></i>
            </div>
          </div>
          <div
            class="flex items-center pb-2"
            v-for="(child, childIndex) in parent.repair_options"
            :key="`child-${childIndex}`"
          >
            <div class="flex gap-2 items-center w-11/12">
              <AppInput
                v-model="child.name"
                type="text"
                rules="required"
                placeholder="e.g. Front Wheel"
                :showError="false"
              />
            </div>

            <div class="flex justify-center w-1/12 item-center">
              <div
                class="col-span-1 cursor-pointer focus:text-gray-400"
                @click="onRemoveSubpartRepairOption(parentIndex, childIndex)"
              >
                <i class="fas fa-minus-circle" style="color: #d90a20"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-else
        class="text-gray-500  border-dashed border border-gray-300 px-2 py-5 text-sm rounded-lg flex items-center justify-center"
      >
        <p>
          {{
            $t(
              'components.vehicleOperationPrimer.addEdit.steps.repairOption.placeholder.noData'
            )
          }}
        </p>
      </div>

      <button type="submit" ref="submitButton" class="hidden">
        Save
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import { deepCompareObjects } from '@/utils'
import { useEndpoints } from '@/composables'

export default {
  name: 'Step3',

  components: {},

  props: {
    formData: {
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,
      form: [],
      toDeleteForm: [],
    }
  },
  created() {
    console.log('step3-created')
  },
  mounted() {
    console.log('step3-mounted')
  },
  computed: {},

  watch: {
    formData: {
      immediate: true,
      deep: false,
      handler(data) {
        if (data) {
          console.log('getStep3FormData', data)
          this.form = [...data]
        }
      },
    },

    form: {
      immediate: false,
      deep: true,
      handler(updatedFormData) {
        if (deepCompareObjects(this.formData, updatedFormData)) {
          return
        }
        this.$emit('dirty', { step: 1, data: this.form })
      },
    },
  },

  methods: {
    onAddSubpartRepairOption(index) {
      this.form[index].repair_options.unshift({ name: '' })
    },
    onRemoveSubpartRepairOption(subpartIndex, repairOptionIndex) {
      if (this.isEditing) {
        this.toDeleteForm.push(
          this.form[subpartIndex].repair_options[repairOptionIndex]
        )
      }
      this.form[subpartIndex].repair_options.splice(repairOptionIndex, 1)
    },
    async submit() {
      this.$refs.submitButton.click()
    },

    async submitStep() {
      let repairOptionRequests = []
      this.form.forEach((subpart) => {
        if (subpart.repair_options.length > 0) {
          subpart.repair_options.forEach((repairOption) => {
            const isUpdateReq = 'id' in repairOption
            const method = isUpdateReq ? 'PATCH' : 'POST'
            const url = isUpdateReq
              ? useEndpoints.operationPrimer.vehicleInspectionSubPartRepairOption.update(
                  repairOption.id
                )
              : useEndpoints.operationPrimer.vehicleInspectionSubPartRepairOption.create()

            const data = new FormData()
            data.append('name', repairOption.name)
            data.append('sub_part', subpart.id)
            const repairOptionReq = this.$http({
              url: url,
              method: method,
              data: data,
            })
            repairOptionRequests.push(repairOptionReq)
          })
        }
      })

      if (this.isEditing && this.toDeleteForm.length) {
        this.toDeleteForm.forEach((element) => {
          if ('id' in element) {
            const repairOptionReq = this.$http({
              url: useEndpoints.operationPrimer.vehicleInspectionSubPartRepairOption.update(
                element.id
              ),
              method: 'DELETE',
            })
            repairOptionRequests.push(repairOptionReq)
          }
        })
      }

      const requests = [...repairOptionRequests]
      // submit
      this.isLoading = true
      await this.$http
        .all(requests)
        .then(
          this.$http.spread(() => {
            this.$emit('save', { step: 3 })
            const message = `Repair Option ${
              this.isEditing ? 'updated' : 'added'
            } successfully`
            this.$notify({
              group: 'bottomLeft',
              type: 'success',
              title: 'Success',
              text: message,
            })
          })
        )
        .catch((err) => {
          // todo: emit fail?
          console.error('Error occurred!', err)
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: 'Error occurred!',
            text: err.response.data.detail,
          })
        })
        .finally(() => (this.isLoading = false))

      // let subPartRequests = []
      // if (this.form.length) {
      //   this.form.forEach((element) => {
      //     console.log(`Elem ${element.type}`, element)
      //     const isUpdateReq = 'id' in element
      //     const method = isUpdateReq ? 'PATCH' : 'POST'
      //     const url = isUpdateReq
      //       ? useEndpoints.operationPrimer.vehicleInspectionSubPart.update(
      //           element.id
      //         )
      //       : useEndpoints.operationPrimer.vehicleInspectionSubPart.create()
      //     const data = new FormData()
      //     data.append('name', element.name)
      //     data.append('part', this.primaryKey)
      //     const subpartReq = this.$http({
      //       url: url,
      //       method: method,
      //       data: data,
      //     })
      //     subPartRequests.push(subpartReq)
      //   })
      // }
      // if (this.isEditing && this.toDeleteForm.length) {
      //   this.toDeleteForm.forEach((element) => {
      //     if ('id' in element) {
      //       const subpartReq = this.$http({
      //         url: useEndpoints.operationPrimer.vehicleInspectionSubPart.delete(
      //           element.id
      //         ),
      //         method: 'DELETE',
      //       })
      //       subPartRequests.push(subpartReq)
      //     }
      //   })
      // }
      // const requests = [...subPartRequests]
      // // submit
      // this.isLoading = true
      // await this.$http
      //   .all(requests)
      //   .then(
      //     this.$http.spread(() => {
      //       this.$http
      //         .get(
      //           useEndpoints.operationPrimer.vehicleInspectionPart.details(
      //             this.primaryKey
      //           )
      //         )
      //         .then((res) => {
      //           const data = res.data.sub_parts
      //           this.$emit('save', { step: 2, data })
      //           const message = `Subpart ${
      //             this.isEditing ? 'updated' : 'added'
      //           } successfully`
      //           this.$notify({
      //             group: 'bottomLeft',
      //             type: 'success',
      //             title: 'Success',
      //             text: message,
      //           })
      //         })
      //         .finally(() => (this.isLoading = false))
      //     })
      //   )
      //   .catch((err) => {
      //     // todo: emit fail?
      //     console.error('Error occurred!', err)
      //     this.$notify({
      //       group: 'bottomLeft',
      //       type: 'error',
      //       title: 'Error occurred!',
      //       text: err.response.data.detail,
      //     })
      //   })
    },
  },
}
</script>

<style lang="scss" scoped></style>
