<template>
  <div>
    <VehicleModelAddEdit
      @add-edit="$store.dispatch('fsTable/fetchData')"
      :roleType="roleType"
    />
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <TitlePlus
            :title="$t('components.vehicleModelManagement.headline')"
            @plus="add"
            :hide-plus="isHidePlus"
          />
        </div>
        <div class="summary-card-container">
          <SummaryCard
            :title="$t('components.vehicleModelManagement.summary.total')"
            :value="indexMetaData.count.total"
            variant="gray"
            size="large"
          />
        </div>
      </div>

      <FSTable
        :fst-id="fstId"
        :searchEnabled="false"
        :headers="getTableHeaders"
        :endpoint="endpoint"
        :qso="qso"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #default="{ data }">
          <template v-for="(item, itemIndex) in data">
            <template v-if="getTableMode === 'full'">
              <FSTableRow :key="itemIndex" text-fallback-always>
                <FSTableRowItem
                  className="text-blue-600 cursor-pointer"
                  @click="edit(item)"
                  :text="roleType === 'owner' ? item.title : item.name"
                />
                <FSTableRowItem :text="getProtocolName(item.iot_protocol)" />
                <FSTableRowItem
                  :text="
                    `${item.max_speed ||
                      '-'} ${item.max_speed_unit_display_text || '-'}`
                  "
                />
                <FSTableRowItem
                  :text="
                    `${parseFloat(item.range) ||
                      '-'} ${item.range_unit_display_text || '-'}`
                  "
                />
                <FSTableRowItem
                  ><div class="flex items-center">
                    <oto-edit-icon @click="edit(item)" /></div
                ></FSTableRowItem>
              </FSTableRow>
            </template>
            <template v-if="getTableMode === 'responsive'">
              <FSTableRow :key="`fs-table-row-${itemIndex}`" textFallbackAlways>
                <FSTableRowItem>
                  <div
                    class="col-span-1 focus:text-gray-400"
                    @click="toggle(itemIndex)"
                  >
                    <i
                      class="fas fa-minus-circle"
                      style="color:#d90a20;"
                      v-if="opened.includes(itemIndex)"
                    ></i>
                    <i class="fas fa-plus-circle" v-else></i>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem
                  className="text-blue-600 cursor-pointer"
                  @click="edit(item)"
                  :text="roleType === 'owner' ? item.title : item.name"
                />
                <FSTableRowItem :text="getProtocolName(item.iot_protocol)" />
              </FSTableRow>
              <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                <td colspan="10">
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.vehicleModelManagement.table.columns.maxSpeed'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      <FSTableRowItem
                        :text="
                          `${item.max_speed ||
                            '-'} ${item.max_speed_unit_display_text || '-'}`
                        "
                      />
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.vehicleModelManagement.table.columns.mileage'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      <FSTableRowItem
                        :text="
                          `${parseFloat(item.range) ||
                            '-'} ${item.range_unit_display_text || '-'}`
                        "
                      />
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.vehicleModelManagement.table.columns.actions'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      <oto-edit-icon @click="edit(item)" />
                    </div>
                  </div>
                </td>
              </FSTableRow>
            </template>
          </template>
        </template>
      </FSTable>
    </content-section>
  </div>
</template>
<script>
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import { FSTable, FSTableRow, FSTableRowItem } from '@/components/fs-table'
import VehicleModelAddEdit from '@/views/vehicle-manufacturers/VehicleModelAddEdit.vue'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import { VehicleModelConfig } from '@/config/VehicleModelConfig'
import { EventBus } from '@/utils'
import { useEndpoints } from '@/composables'
import SummaryCard from '@/components/cards/SummaryCard'
export default {
  name: 'VehicleModelIndex',
  props: {
    fstId: {
      type: String,
      default: 'vehicle-model-index',
      required: false,
    },
    qso: {
      type: Object,
      default: () => ({ append: '', prepend: '?' }),
    },
    endpoint: {
      type: String,
      default: '',
      required: false,
    },
    roleType: {
      type: String,
      default: 'admin',
      required: false,
    },
    isHidePlus: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  components: {
    ContentSection,
    TitlePlus,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    OtoEditIcon,
    VehicleModelAddEdit,
    SummaryCard,
  },

  data() {
    return {
      indexMetaData: {
        count: {
          total: 0,
        },
      },
      windowWidth: window.innerWidth,
      opened: [],
      tableHeaders: [
        {
          text: `${this.$t(
            'components.vehicleModelManagement.table.columns.name'
          )}`,
          width: '30%',
          sort: 'name',
        },
        {
          text: `${this.$t(
            'components.vehicleModelManagement.table.columns.protocol'
          )}`,
          width: '30%',
          sort: 'protocol',
        },

        {
          text: `${this.$t(
            'components.vehicleModelManagement.table.columns.maxSpeed'
          )}`,
          width: '20%',
          sort: 'maxSpeed',
        },

        {
          text: `${this.$t(
            'components.vehicleModelManagement.table.columns.mileage'
          )}`,

          width: '20%',

          sort: 'mileage',
        },

        {
          text: `${this.$t(
            'components.vehicleModelManagement.table.columns.actions'
          )}`,
          width: '10%',
          sort: 'actions',
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: `${this.$t(
            'components.vehicleModelManagement.table.columns.name'
          )}`,
          width: '45%',
          sort: 'name',
        },
        {
          text: `${this.$t(
            'components.vehicleModelManagement.table.columns.protocol'
          )}`,
          width: '45%',
          sort: 'protocol',
        },
      ],
      iotProtocols: [],
      lockManufactures: [],
      vehicleManufactures: [],
    }
  },
  async created() {
    this.iotProtocols = await this.$http
      .get(useEndpoints.dropdown.lockTypes())
      .then((res) => res.data.data)
      .catch((err) => console.log('lockTypesErr = ', err.response))

    this.lockManufactures = await this.$http
      .get(useEndpoints.dropdown.iot_manufacture())
      .then((res) => res.data.data)
      .catch((err) => console.log('iotManufactureErr = ', err.response))

    this.vehicleManufactures = await this.$http
      .get(useEndpoints.dropdown.vehicle_manufacture())
      .then((res) => res.data.data)
      .catch((err) => console.log('vehicleManufactureErr = ', err.response))
  },
  computed: {
    lockManufacturerId() {
      return this.$route.params.id
    },
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    add() {
      EventBus.$emit(VehicleModelConfig.events.editingData, {
        iotProtocols: this.iotProtocols,
        lockManufactures: this.lockManufactures,
        vehicleManufactures: this.vehicleManufactures,
        lockManufacturerId: this.lockManufacturerId,
        item: {},
      })
      dispatchEvent(new Event(VehicleModelConfig.events.sorToggle))
    },
    edit(item) {
      console.log(item)
      EventBus.$emit(VehicleModelConfig.events.editingData, {
        item: item,
        iotProtocols: this.iotProtocols,
        lockManufactures: this.lockManufactures,
        vehicleManufactures: this.vehicleManufactures,
      })
      dispatchEvent(new Event(VehicleModelConfig.events.sorToggle))
    },
    getProtocolName(protocolId) {
      const selectedProtocols = this.iotProtocols.find(
        (item) => item.value === protocolId
      )
      return selectedProtocols ? selectedProtocols.display_name : protocolId
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
}
</script>
<style lang="scss" scoped>
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
