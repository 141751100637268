var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('slide-over-right',{attrs:{"id":_vm.sorId,"fullWidth":true,"editType":"charging-dock-port"},on:{"showModal":_vm.showModal},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"flex gap-2"},[_c('slide-over-right-trigger',{attrs:{"id":_vm.sorId}},[_c('div',{staticClass:"items-center justify-center mr-4 cursor-pointer back-button"},[_c('i',{staticClass:"fas fa-arrow-left"})])]),_c('div',[_c('div',{staticClass:"font-semibold text-19px text-oCharcoal flex"},[_vm._v(" ID: "),_c('span',[_vm._v(" "+_vm._s(_vm.dockData.kuhmute_hub_uuid)+" ")])]),_c('div',[_c('span',[_vm._v("Name: "+_vm._s(_vm.dockData.name || '--')+", Serial Number: "+_vm._s(_vm.dockData.id || '--'))])])])],1)]},proxy:true}])},[_c('loading',{attrs:{"active":_vm.isLoading},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('div',{key:("chargingDockPortIndex_" + _vm.counter),staticClass:" mt-3 pt-5 border-oLightestGray border-t-2"},[_c('STable',{attrs:{"sId":'charging-dock-port-list',"headers":_vm.chargingDockPortHeaders}},[_vm._l((_vm.chargingDockPortList),function(chargingDockPortItem,chargingDockPortItemIndex){return [_c('STableRow',{key:chargingDockPortItemIndex,attrs:{"text-fallback-always":""}},[_c('STableRowItem',{attrs:{"text":chargingDockPortItem.number}}),_c('STableRowItem',{attrs:{"text":_vm.getFormattedDateTime(
                chargingDockPortItem.updated,
                'D MMM YYYY, h:mm:ss a'
              )}}),_c('STableRowItem',[(chargingDockPortItem.bike)?_c('router-link',{class:"text-blue-600 mr-5",attrs:{"to":{
                name: 'ViewVehicleProfile',
                params: { id: chargingDockPortItem.bike.id },
              },"target":"_blank"}},[_vm._v(" "+_vm._s(chargingDockPortItem.bike.qr_code)+" ")]):_c('span',[_vm._v("--")])],1),_c('STableRowItem',{attrs:{"text":chargingDockPortItem.bike
                ? chargingDockPortItem.bike.lock.lock_id
                : '--'}}),_c('STableRowItem',{attrs:{"text":chargingDockPortItem.bike
                ? chargingDockPortItem.bike.charging_adapter
                  ? chargingDockPortItem.bike.charging_adapter.adapter_code
                  : '--'
                : '--'}}),_c('STableRowItem',[_c('x-status',{attrs:{"variant":_vm.getPortStatusVariant(chargingDockPortItem.status),"text":_vm.getPortStatusText(chargingDockPortItem.status),"profile":"vehicle"}})],1)],1)]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }